@import './assets/styles/mixins.scss';
@import './assets/styles/variables.scss';


html,
body {
  height: 100vh;
}

body {
  margin: 0;

  @include scrollbar(0.3125rem, rgb(119, 119, 119), #f7f9fc, 00.15625rem);
}

.doctor-profile nb-user .user-container .info-container .user-name {
  font-size: 1.3rem !important;
}

.doctor-profile .ah-wrapper {
  min-width: 720px !important;
}

.doctor-profile nb-select .select-button {
  min-width: 100px !important;
}

.patient-profile .ah-wrapper {
  min-width: 720px !important;
}

.patient-profile .transactions-wrapper {
  min-width: 700px !important;
}

.patient-profile .topup-history-wrapper {
  min-width: 550px !important;
}

nb-tabset .tab.active .tab-link::before {
  background-color: #7cc353 !important;
  color: #7cc353 !important;
}

nb-tabset .tab.active .tab-link {
  color: #7cc353 !important;
}

nb-tabset .tab .tab-link:hover {
  color: #7cc353 !important;
}

nb-tabset .tab .tab-link:hover::before {
  background-color: #7cc353 !important;
}

.header-container nb-icon,
.header-container nb-user .user-name,
.header-container nb-user .initials {
  color: whitesmoke !important;
}

.tox-tinymce {
  border-radius: 0.25em !important;
  border-color: #e4e9f2 !important;
}

.tox-toolbar {
  background-color: #f7f9fc !important;
  border-color: #e4e9f2 !important;
}

.tox-edit-area__iframe,
.tox-menubar,
.tox-toolbar__primary {
  background-color: #f7f9fc !important;
  border-color: #e4e9f2 !important;
}

.tox-toolbar__group {
  border-color: #e4e9f2 !important;
}

.tox-toolbar__overflow {
  background-color: #f7f9fc !important;
  border-color: #e4e9f2 !important;
}

.banner-tabset ul.tabset {
  display: flex !important;
  justify-content: center !important;
}

.banner-actions nb-icon {
  color: white !important;
}

.page-item.active .page-link {
  background-color: #7cc353;
  /* #2ce69b; */
  border-color: #7cc353;
  /* #2ce69b; */
}

button {
  line-height: 1.25rem !important;
}

ngb-pagination ul li {
  font-size: 12px !important;
}

/* list */
.listHeader {
  background-color: #7cc353;
  color: white;
  font-weight: 600;
  padding: 0;
  height: 32px;
  border-radius: 0.25em;
}

#nb-list-id {
  background: white;
}

#nb-list-id nb-actions {
  color: #7cc353;
  margin: 0.2em !important;
}

#nb-list-id nb-action {
  font-size: 16px;
}

ngb-pagination {
  margin-top: 10px;
}

.nb-theme-default [nbButton].appearance-filled.size-small {
  padding: 0.5rem 0.875rem;
}

.nb-theme-default [nbButton].appearance-filled.status-success {
  background-color: #7cc353 !important;
  border-color: #7cc353 !important;
  color: white !important;
}

.nb-theme-default [nbButton].appearance-outline.status-success {
  background-color: rgba(0, 214, 143, 0.08);
  border-color: #7cc353 !important;
  color: #7cc353 !important;
}

.nb-theme-default nb-toggle.status-success .toggle.checked {
  background-color: #7cc353 !important;
  border-color: #7cc353 !important;
}

.nb-theme-default nb-toggle.status-success .toggle {
  background-color: rgba(0, 214, 143, 0.08);
  border-color: #7cc353 !important;
}

.nb-theme-default nb-toggle.status-success .toggle-switcher nb-icon {
  color: #7cc353 !important;
}

.nb-theme-default nb-card.status-success nb-card-header {
  background-color: #7cc353 !important;
  border-bottom-color: #7cc353 !important;
}

/*  */
.orange {
  color: orange !important;
}

.green {
  color: green !important;
}

.red {
  color: red !important;
}

.brown {
  color: brown !important;
}

.action-icon-size {
  font-size: 20px;
}

.no-data-div {
  padding: 25px;
  text-align: -webkit-center;
  text-align: center;
  color: lightgrey;
  font-size: 27px;
  font-weight: 600;
}

.icon-gray {
  color: #8f9bb3;
}

.custom-icon-size {
  font-size: 18px;
}

.p-l {
  padding-left: 0 !important;
}

.p-r {
  padding-right: 0 !important;
}

.nb-theme-default [nbButton].appearance-filled.status-success:hover {
  background-color: #81b214 !important;
  border-color: #81b214 !important;
}

nb-list-item {
  padding: 1rem !important;
  height: auto !important;
}

.required-star {
  color: red;
}

.require-description-p {
  font-style: italic;
  font-size: 14px;
  font-weight: 600;
  padding: 0 15px;
  margin-top: 86px;
}

.require-show-txt {
  color: red;
  font-style: italic;
  font-size: 12px;
}

.my-label-control {
  font-weight: 600;
  border: 1px solid #e4e9f2;
  background: lightgrey;
  padding: 9px 17px;
  width: 100%;
  border-radius: 3px;
  min-height: 40px;
}

.my-div-section-marginTop {
  margin-top: 7px;
}

.add-package-doctor-select-box .p-multiselect {
  width: 100%;
}

.add-package-doctor-select-box .p-multiselect-label {
  text-overflow: unset;
  overflow: auto;
}

.p-dropdown {
  width: 100% !important;
}

.validation {
  color: red;
  margin-top: -8px;
  margin-bottom: 8px;
}

.nb-theme-default nb-layout-header nav {
  height: var(--layout-header-height);
}

.text-highlight {
  background-color: #e95420;
  color: #fff;
}

.nb-theme-default li {
  color: inherit;
}

.nb-theme-default .outgoing p {
  color: inherit;
}

// tinymce overwrites
.input-box {
  .mce-content-body {
    max-height: 40vh;
    overflow-y: scroll;
    font-size: 1rem;

    p {
      font-size: 1rem;
    }

    padding: 12px 10px;
    transition: 0.3s;
    border: 1px solid #c5cee0;
    border-radius: 5px;

    &:focus {
      background-color: #edf1f7;
    }

    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .mce-content-body::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .mce-content-body {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

// tinymce input placeholder position is a little off, so I overwrote it using this
.mce-content-body:not([dir='rtl'])[data-mce-placeholder]:not(.mce-visualblocks)::before {
  left: inherit !important;
}
