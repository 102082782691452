@mixin scrollbar($size, $foreground-color, $background-color, $border-radius) {
  &::-webkit-scrollbar {
    width: $size;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
    border-radius: $border-radius;
  }
}
